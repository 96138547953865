import React from 'react';

import cx from 'classnames';

import styles from './styles.module.scss';

const Heading = ({
  children,
  className,
  size, //small, medium, large
  color, // white, blue, grey, darkBlue
  ...props
}) => {
  const tagProps = {
    ...props,
    className: cx(
      styles.heading,
      styles[size],
      styles[`${color}Text`],
      className
    )
  };

  switch (size) {
    case 'large':
      return <h1 {...tagProps}>{children}</h1>;
    case 'medium':
      return <h2 {...tagProps}>{children}</h2>;
    case 'small':
      return <h3 {...tagProps}>{children}</h3>;

    default:
      return <h1 {...tagProps}>{children}</h1>;
  }
};

export default Heading;
