import React, { useState } from 'react';

import cx from 'classnames';

import { Image } from 'components';

import { getSnippetUrl } from './utils';

import styles from './styles.module.scss';

const PhotoToVideoEmbed = ({
  video,
  className,
  image,
  imageClassName,
  text
}) => {
  const [showVideo, setShowVideo] = useState(false);

  return (
    <div
      onClick={() => setShowVideo(true)}
      className={cx(styles.container, className)}>
      <span className={styles.loader} />
      {showVideo ? (
        <iframe
          title={text}
          className={className}
          allow="accelerometer; autoplay; encrypted-media;"
          allowFullScreen
          frameBorder="0"
          height="100%"
          width="100%"
          src={getSnippetUrl(video)}
        />
      ) : (
        <React.Fragment>
          <Image
            src={image}
            height="100%"
            width="100%"
            className={imageClassName}
          />
          <div className={styles.btnAndText}>
            <div className={styles.button} />
            <div className={styles.text}>{text}</div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default PhotoToVideoEmbed;
