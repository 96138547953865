import React from 'react';

import waves from './assets/wavy_lines1.png';

import { Image } from 'components';

import styles from './styles.module.scss';

const Waves = () => <Image className={styles.waves} src={waves} />;

export default Waves;
