import React from 'react';

import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { useStaticQuery, graphql } from 'gatsby';

import metaImagePath from 'images/metaImage.jpg';

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  const { siteUrl } = site.siteMetadata;

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}>
      <meta name="description" content={metaDescription} />
      <meta name="og:title" content={site.siteMetadata.title} />
      <meta name="og:description" content={metaDescription} />
      <meta name="og:type" content="website" />
      <meta name="titter:card" content="summary_large_image" />
      <meta name="titter:creator" content={site.siteMetadata.author} />
      <meta name="titter:title" content={site.siteMetadata.title} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="og:image" content={`${siteUrl}${metaImagePath}`} />
      <meta property="twitter:image" content={`${siteUrl}${metaImagePath}`} />
      <title>{site.siteMetadata.title}</title>
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  siteUrl: PropTypes.string
};

export default SEO;
