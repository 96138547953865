import React from 'react';
import TagManager from 'react-gtm-module';

import { Navigation } from 'components';

import { tagManagerArgs } from './constants';
import styles from './styles.module.scss';

class Layout extends React.Component {
  componentDidMount() {
    TagManager.initialize(tagManagerArgs);
  }

  render() {
    const {
      children,
      page,
      openBecomeASpeakerModal,
      openBecomeAPartnerModal,
      openBecomeASponsorModal
    } = this.props;

    return (
      <>
        <Navigation
          page={page}
          openBecomeASponsorModal={openBecomeASponsorModal}
          openBecomeASpeakerModal={openBecomeASpeakerModal}
          openBecomeAPartnerModal={openBecomeAPartnerModal}
        />
        <div className={styles.wrapper}>{children}</div>
      </>
    );
  }
}

export default Layout;
