import React, { useRef, useEffect, useState, useCallback } from 'react';

import _ from 'lodash';

const SelfIncrementingNumber = ({ number, suffix, className }) => {
  const numberContainer = useRef(null);
  const [wasVisible, setWasVisible] = useState(false);

  const handleScroll = useCallback(
    event => {
      if (!numberContainer || wasVisible) {
        return;
      }

      const bounding = numberContainer.current.getBoundingClientRect();

      // check if the element is visible;
      if (
        bounding.top >= 0 &&
        bounding.bottom <=
          (window.innerHeight || document.documentElement.clientHeight)
      ) {
        setWasVisible(true);
      }
    },
    [wasVisible]
  );

  useEffect(() => {
    // executed on mount
    handleScroll(); // we need to check if the element is visible initially
    window.addEventListener('scroll', handleScroll);

    // executed on unmount
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll, wasVisible]);

  useEffect(() => {
    if (wasVisible) {
      const target = parseInt(numberContainer.current.innerText);

      _.range(target + 1).map((number, index) =>
        setTimeout(
          () => (numberContainer.current.innerText = number),
          (900 * index) / (target + 1)
        )
      );
    }
  }, [wasVisible]);

  return (
    <div className={className}>
      <span ref={numberContainer}>{wasVisible ? number : 0}</span>
      {suffix}
    </div>
  );
};
export default SelfIncrementingNumber;
