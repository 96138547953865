import React from 'react';

import cx from 'classnames';

import styles from './styles.module.scss';

const Button = ({
  className,
  children,
  size, // small, medium
  rounded = true, // boolean
  textColor, // white, blue, grey, darkBlue
  borderColor, // blue, grey, darkBlue
  backgroundColor = 'white',
  onClick // white, blue, grey, darkBlue
}) => (
  <button
    onClick={onClick}
    className={cx(
      styles.button,
      styles[size],
      { [styles.rounded]: rounded },
      [styles[`${textColor}Text`]],
      [styles[`${borderColor}Border`]],
      [styles[`${backgroundColor}Background`]],
      className
    )}>
    {children}
  </button>
);

export default Button;
