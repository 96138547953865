import React, { useState } from 'react';

import _ from 'lodash';
import cx from 'classnames';

import { Image, ExternalLink } from 'components';

import styles from './styles.module.scss';

const Collapsible = ({
  items = [],
  className,
  singleSubItemPerLine = false
}) => {
  const [activeItems, setActiveItems] = useState([items[0]]);

  return (
    <div className={cx(className, styles.container)}>
      {_.map(items, (item, index) => {
        const isActive = _.includes(activeItems, item);
        const { title, items: subItems } = item;

        return (
          <div
            key={`item-of-collapsible-${title}-${index}`}
            className={cx(styles.item, { [styles.active]: isActive })}>
            <div
              className={cx(styles.titleRow, { [styles.active]: isActive })}
              onClick={() => setActiveItems(_.xor(activeItems, [item]))}>
              <span className={styles.title}>{title}</span>
              <span className={styles.toggler}>
                <i />
              </span>
            </div>

            <div
              className={cx(styles.content, {
                [styles.singleSubItemPerLine]: singleSubItemPerLine
              })}>
              {_.map(subItems, ({ text, image }) => {
                let renderImage = null;

                if (!_.isNil(image)) {
                  if (image.href) {
                    renderImage = (
                      <ExternalLink href={image.href} className={styles.image}>
                        <Image
                          height={image.height}
                          width={image.width}
                          src={image.src}
                        />
                      </ExternalLink>
                    );
                  } else {
                    renderImage = (
                      <Image
                        className={styles.image}
                        height={image.height}
                        width={image.width}
                        src={image.src}
                      />
                    );
                  }
                }

                return (
                  <span className={styles.subItem}>
                    <i className={styles.arrow} />
                    {text}
                    {renderImage}
                  </span>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Collapsible;
