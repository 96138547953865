import React, { forwardRef } from 'react';

import { ReactTypeformEmbed } from 'react-typeform-embed';

const TypeformEmbed = forwardRef(({ url }, ref) => (
  <ReactTypeformEmbed
    popup
    autoOpen={false}
    mode="drawer_left"
    url={url}
    ref={ref}
  />
));

export default TypeformEmbed;
