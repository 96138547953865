import React, { useState, useEffect } from 'react';

import cx from 'classnames';

import { Button, ExternalLink } from 'components';

import styles from './styles.module.scss';

const Navigation = ({
  page,
  openBecomeASpeakerModal,
  openBecomeAPartnerModal,
  openBecomeASponsorModal
}) => {
  const [shrinkNavigation, setShrinkNavigation] = useState(false);
  const [expandMobileNavigation, setExpandMobileNavigation] = useState(false);

  const handleScroll = event => {
    const verticalScroll = window.scrollY;

    setShrinkNavigation(verticalScroll > 20);
  };

  useEffect(() => {
    // executed on mount
    window.addEventListener('scroll', handleScroll);

    // executed on unmount
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div
      className={cx(
        styles.wrapper,
        { [styles.shrink]: shrinkNavigation },
        {
          [styles.expandMobile]: expandMobileNavigation
        }
      )}>
      <div className={styles.container}>
        <div className={styles.left}>
          <div
            className={cx(styles.logo, styles.link)}
            onClick={() =>
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            }>
            connect
          </div>

          <div
            className={cx(styles.menu, {
              [styles.open]: expandMobileNavigation
            })}
            onClick={() => setExpandMobileNavigation(!expandMobileNavigation)}>
            <span />
            <span />
            <span />
            <span />
          </div>
        </div>

        <div className={styles.right}>
          <div className={styles.link} onClick={openBecomeASpeakerModal}>
            Become a Speaker
          </div>

          <ExternalLink
            href="https://www.unissu.com/connect-videos"
            className={styles.link}>
            Connect Videos
          </ExternalLink>

          <div className={styles.link} onClick={openBecomeAPartnerModal}>
            Partner
          </div>

          <Button
            size="small"
            backgroundColor="white"
            textColor="darkBlue"
            borderColor="darkBlue"
            className={styles.button}
            onClick={openBecomeASponsorModal}>
            Sponsor
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
