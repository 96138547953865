import React from 'react';

import _ from 'lodash';
import cx from 'classnames';

import styles from './styles.module.scss';

const Paragraph = ({
  children,
  className,
  size, //small, medium, large
  color, // white, blue, grey, darkBlue
  ...props
}) => (
  <p
    className={cx(
      styles.paragraph,
      styles[size],
      styles[`${color}Text`],
      { [styles.link]: !_.isNil(props.onClick) },
      className
    )}
    {...props}>
    {children}
  </p>
);

export default Paragraph;
